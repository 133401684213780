#login {
  margin: 15% auto;
  width: 50%;

  .input-field {
    margin-bottom: 4rem;
  }

  .btn {
    float: right;
  }
}

#logout {
  position: fixed;
  bottom: 20px;
  right: 20px;

  i {
      font-size: 3rem;
  }
}
