@import 'login.scss';
@import 'projects.scss';
@import 'project.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

i.link, a i.link, span.link {
  opacity: .7;
  cursor: pointer;
  color: #333;

  &:hover{
    opacity: 1;
  }
}

.c-btn span {
  position: relative;
  top: -5px;
  left: 2px;
}

html {
  background: #fefefe;
}

.input-field input:focus + label, .input-field .prefix.active {
  color: #f48fb1  !important;
}

input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #f48fb1;
  box-shadow: 0 1px 0 0 #f48fb1;
}

.input-field>label:not(.label-icon) {
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0;
}

.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .5);
  z-index: 1000;
  padding: 150px 0;

  .card {
    width: 30%;
    margin: auto;
    border-radius: 5px;

    .card-title {
      padding: 0.2em 1em;
      border-bottom: 1px solid #c0c0c059;
    }
  }


  .card-footer {
    padding: 1em;
    text-align: right;
    padding: 24px 2em 1.5em;

    .btn {
      margin-left: 10px;
    }
  }
}

.ck-editor ul li {
  list-style-type: circle;
}

::selection {
  background: #f48fb1;
  color: #fff /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: #f48fb1;
  color: #fff /* Gecko Browsers */
}
