.project {
  .project-form, .table-header {
    width: 70%;
    margin: 50px auto;
  }

  .input-field.url {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input {
      width: 96%;
    }

    .btn-floating {
      width: 25px;
      height: 25px;
      line-height: 25px;

      i {
       line-height: 25px;
       font-size: 1.2rem;
      }
    }

    .btn-floating + .btn-floating {
      margin-left: 7px;
    }
  }

  .table-header, .tasks-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .table-header h4, .tasks-header h4 {
    margin: 0;
  }

  .table-header i {
    font-size: 2rem;
  }

  .table-header i:nth-of-type(1) {
    margin-right: 10px;
  }

  .tasks-header {
    margin: 20px 0;
  }

  .tasks {
    width: 70%;
    margin: 50px auto;
  }

  .task {
    margin: 0;
  }

  .input-field {
    margin: 2rem 0;
  }

  .task .editor {
    margin: 4rem 0;
  }

  .task .ck-editor {
    margin-top: 10px;
  }

  .task .card-content {
    padding: 30px 80px;
  }

  .task .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline {
    padding: 10px 40px;
  }

  .task .ctrl {
    text-align: right;
    margin: 20px 0;
  }

 .task .collapsible-header {
   display: flex;
   align-items: center;
   justify-content: space-between;
   cursor: pointer;
 }

 .task .task-title {
   display: flex;
   align-items: center;

   i.link {
     margin: 0;
   }
 }
}
