#projects {
  min-height: 100vh;

  .projects-table,
  .table-header {
    width: 70%;
    margin: 50px auto;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  .table-header h4 {
    margin: 0;
  }

  .projects-table table td:last-child {
    width: 130px;
    text-align: right;

    i.indigo-text {
      margin: 0 5px;
    }
  }

  .projects-table .link-dnd {
    width: 60px;
  }

  .filterable {
    position: relative;
    padding: 0;
  }

  .filterable i {
    position: absolute;
    top: 20px;
    left: 4px;
    font-size: 20px;
  }

  .filterable input {
    height: 2rem;
  }

  .filterable input::placeholder {
    color: #333;
    font-weight: 600;
  }

  .filterable input {
    border: 0 solid transparent;
    padding-left: 30px;
    margin: 0;
    width: 70%;
  }

  tbody tr td:nth-of-type(2) {
    padding-left: 30px;
  }

  .filterable input {
    box-shadow: none;
  }

  .link-preview {
    position: fixed;
    box-shadow: 0 0 5px #cfcfcf;
    width: 520px;
    background: #fbfbfb;
    padding: 16px;
    z-index: 2;
    top: 20px;
    left: 20px;
    display: none;
    display: flex;
    align-items: center;

    input[type=text]:not(.browser-default),
    input[type=text]:not(.browser-default):focus:not([readonly]) {
      margin-right: 5px;
      text-align: center;
      border-bottom: 0;
      box-shadow: 0 0 0 0 #f48fb1;
      margin-bottom: 0 !important;
    }

    .open-link {
      margin-top: 6px;
    }
  }

  .sortable .sort-ctrls {
    margin: 0 5px;

    i {
      color: #dfdfdf;
      cursor: pointer;
    }

    i.default {
      color: #333;
    }
  }

  .sortable .sort-ctrls i.desc {
    position: relative;
    left: -24px;
    top: 15px;
  }
}
